.card {
  margin-top: 2rem;
  width: 20rem;
  height: 10rem;
  background-color: #d8b6ff;
  gap: 2rem;
  text-align: center;
  transition: all 0.3s;
  border-radius: 8px;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;

  color: white;
}

.card:hover {
  background-color: #e6e6fa;
  color: black;
}

.boxStyle2 {
  width: 20rem;
  height: 25rem;
  margin: 1rem;
  background-color: #f0f0fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-bottom: 50px;
}

.boxStyle2:hover {
  background-color: #dcdcf9;
}
